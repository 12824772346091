import { React, useState, useEffect, useMemo, useRef } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import invokeLambda from "../lambda/invoke";
import { isDevMode, sendMailLambdaName } from "../env";

const SEND_MAIL_STATUS_CODE = {
  OK: "OK",
  ERROR: "ERROR",
};

const ContactForm = () => {
  const getInitialState = () => ({
    scope: "informatica",
    email: "",
    cell: "",
    message: "",
  });

  const [formState, setFormState] = useState(getInitialState());
  const [errorState, setErrorState] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [sendMailState, setSendMailState] = useState();

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevState = usePrevious({ openSnackbar });

  const validEmail = (email) => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    // !!email becaouse email is required
    if (!!email && regex.test(email.replace(/\s/g, ""))) {
      return true;
    }
    return false;
  };

  const validCell = (cell) => {
    let regex = /^[0-9]+$/i;
    if (cell) {
      if (regex.test(cell.replace(/\s/g, ""))) {
        return true;
      }
      return false;
    }
    return true;
  };

  const validationFunctions = {
    email: validEmail,
    cell: validCell,
  };

  const disableSubmitButton = useMemo(
    () => !formState.email || Object.values(errorState).includes(true),
    [formState.email, errorState]
  );

  const handleInputEvents = (event) => {
    event.preventDefault();
    const targetName = event.target.name;
    const value = event.target.value;
    const validationFunction = validationFunctions[targetName];
    const update = {
      [targetName]: value,
    };
    const error = {};
    if (validationFunction) {
      error[targetName] = !validationFunction(value);
    }
    setFormState((prevState) => ({ ...prevState, ...update }));
    setErrorState((prevState) => ({ ...prevState, ...error }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // this lambda accept payload with a body string
    invokeLambda(sendMailLambdaName, { body: formState })
      .then(() => {
        setSendMailState(SEND_MAIL_STATUS_CODE.OK);
      })
      .catch((err) => {
        console.log(err);
        setSendMailState(SEND_MAIL_STATUS_CODE.ERROR);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // restore initial form state after close snackbar
  useEffect(() => {
    if (prevState?.openSnackbar !== openSnackbar && !openSnackbar) {
      if (SEND_MAIL_STATUS_CODE.ERROR !== sendMailState) {
        setTimeout(() => {
          setErrorState({});
          setFormState(getInitialState());
        }, 100);
      }
      setTimeout(() => {
        setSendMailState();
      }, 100);
    }
  }, [openSnackbar, sendMailState, prevState?.openSnackbar]);

  // open snackbar when sendMailState is set
  useEffect(() => {
    if (!!sendMailState) {
      setOpenSnackbar(true);
    }
  }, [sendMailState]);

  const alertSeverity = useMemo(
    () => (SEND_MAIL_STATUS_CODE.OK === sendMailState ? "success" : "error"),
    [sendMailState]
  );

  const alertMessage = useMemo(
    () =>
      SEND_MAIL_STATUS_CODE.OK === sendMailState
        ? "Mail inviata con successo, verrai ricontattato appena possibile!"
        : "OPS! Qualcosa è andato storto!",
    [sendMailState]
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={alertSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {/* 
      <FormControl fullWidth>
        <FormLabel>Scopo del contatto</FormLabel>
        <RadioGroup
          name="scope"
          onChange={handleInputEvents}
          value={formState.scope}
        >
          <FormControlLabel
            value="informatica"
            control={<Radio />}
            label="Informatica"
          />
          <FormControlLabel
            value="cinofilia"
            control={<Radio />}
            label="Cinofilia"
          />
        </RadioGroup>
        <TextField
          required
          label="Email"
          name="email"
          value={formState.email}
          onChange={handleInputEvents}
          error={errorState?.email}
          helperText={errorState?.email && "Formato email non valido"}
          sx={{ m: 2 }}
        />
        <TextField
          label="Cell"
          name="cell"
          value={formState.cell}
          onChange={handleInputEvents}
          error={errorState?.cell}
          helperText={errorState?.cell && "Formato non valido"}
          sx={{ m: 2 }}
        />
        <TextareaAutosize
          name="message"
          minRows={5}
          placeholder="Ciao Luca, ti contatto perchè..."
          value={formState.message}
          onChange={handleInputEvents}
          style={{ margin: "1em" }}
        />
        <Button
          variant="contained"
          disabled={disableSubmitButton}
          onClick={handleSubmit}
          sx={{ m: 2 }}
        >
          Invia
        </Button>
      </FormControl>
      */}
      {isDevMode && <div>{JSON.stringify(formState)}</div>}
      {isDevMode && <div>{JSON.stringify(errorState)}</div>}
      {isDevMode && <div>{JSON.stringify(sendMailState)}</div>}
    </>
  );
};

export default ContactForm;
