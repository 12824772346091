import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
// IMAGES
import LogoZampa from "../static/images/logo-zampa.png";

const Logo = () => (
  <>
    <Box align="center" display={{ xs: "none", sm: "block" }}>
      <img
        src={LogoZampa}
        alt="LUCA NEOTTI"
        width="20%"
        height="20%"
        margin={2}
      />
    </Box>
    <Box align="center" display={{ xs: "block", sm: "none" }}>
      <img
        src={LogoZampa}
        alt="LUCA NEOTTI"
        width="20%"
        height="20%"
        margin={1}
      />
    </Box>
  </>
);

const Introduzione = () => {
  const theme = useTheme();
  return (
    <>
      <Box alignItems="center" padding={2}>
        <Typography variant="h5">Mi presento</Typography>
        <Typography align="justify">
          Mi chiamo Luca, sono nato il 5 maggio del 1989 e abito a Castelleone (CR).
        </Typography>
        <Typography align="justify">
          Nel 2016 comincio un percorso educativo con il cane della mia allora
          fidanzata. Grazie a questo percorso rimango affascinato dal mondo
          cinofilo, soprattutto per quanto riguarda la comunicazione col cane e
          tra cani. I cani possiedono una grande abilità comunicativa fatta di dettagli, di
          sguardi, di posture, di traiettorie, emozioni, odori. "Vedere" e comprendere tutto questo mi ha da subito incuriosito parecchio.
          Mi dispiace non aver fatto prima questa scoperta; sicuramente non mi sarei perso
          un sacco di cose che non ho saputo cogliere per ignoranza in materia.
          Ricordo mio nonno che, quando il suo cane cercava con diverse 
          strategie di farsi capire, diceva che gli mancava la parola: beh, 
          io oggi dico che non gli manca, semplicemente parla una lingua diversa dalla nostra!
        </Typography>
      </Box>
      <Box alignItems="center" padding={2}>
        <Typography align="justify">
          Dopo aver seguito diversi seminari su varie tematiche riguardanti la
          cinofilia, nel 2018 ho conseguito con successo l'esame da{" "}
          <Typography
            style={{
              display: "inline",
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
          >
            Educatore Cinofilo
          </Typography>
          . Da quel momento il mio percorso di formazione cinofila non si è mai
          fermato: ho continuato a seguire seminari, webinar, corsi con grande
          passione. Mi sono formato in diversi settori, dalla comunicazione, agli stati d'animo,
           alla preparazione atletica...
        </Typography>
        La mia mission principale oggi è cercare di trasmettere questa conoscenza e questa sensibilità
        a chi vive con un cane. Poter comprendere meglio il nostro cane ci permette 
        di viverlo in maniera diversa e più sana, di rspettare le sue esigenze
        e di comprendere i sui bisogni.
        Solo con la comprensione si potrà instaurare un buon rapporto basato sulla fiducia e
        sul rispetto e si potrà chiedere ed ottenere qualcosa in cambio al cane.
      </Box>
      <Box alignItems="center" padding={2}>
        <Typography
          style={{
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
        >
          Vivere con un cane significa essere responsabili della sua felicità,
          dal momento in cui lo scegliamo abbiamo il dovere di regalargli
          una vita felice.
        </Typography>
      </Box>
    </>
  );
};

const Links = () => (
  <Box alignItems="center" padding={2}>
    <Typography align="justify">
      Potete visionare qui il mio{" "}
      <Link href="/cinofilia/curriculum">Curriculum cinofilo</Link> e le{" "}
      <Link href="/cinofilia/qualifiche">Qualifiche</Link> ottenute.
    </Typography>
  </Box>
);

const CinofiliaHome = () => (
  <>
    <Logo />
    <Introduzione />
    <Links />
  </>
);

export default CinofiliaHome;
