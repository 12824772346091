import DiplomaFISC from "../static/images/qualifiche/Diploma_FISC.jpg";
import TesserinoTecnicoFISC from "../static/images/qualifiche/tesserino_tecnico_FISC.jpg";
import DiplomaFICSS from "../static/images/qualifiche/Diploma_FICSS.jpg";
import K9CTS from "../static/images/qualifiche/K9CTS.png";

export const qualifiche = [
  {
    titolo: "Educatore cinofilo FICSS",
    image: DiplomaFICSS,
  },
  {
    titolo: "Educatore cinofilo FISC",
    image: DiplomaFISC,
  },
  {
    titolo: "Tesserino tecnico FISC",
    image: TesserinoTecnicoFISC,
  },
  {
    titolo: "Istruttore K9 Cross Training Specialist",
    image: K9CTS,
  },
];
